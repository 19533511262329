/*
 * CB Menu Bar
 */

@import "apricot-variable";
@import "../mixins/color";

.cb-menu-bar {
  &.cb-menu-bar-right {
    @include box-shadow-left();

    position: fixed;
    z-index: 1023;
    right: 0;

    min-width: 240px;
    min-height: 100vh;
  }

  &.cb-menu-bar-left {
    @include box-shadow-right();

    position: fixed;
    z-index: 1023;
    left: 0;

    min-width: 240px;
    min-height: 100vh;
  }

  &.cb-menu-bar-top {
    position: fixed;
    z-index: 1024;
    top: 0;

    padding-top: 24px;
    padding-bottom: 24px;

    @include box-shadow-bottom();
  }

  &.cb-menu-bar-bottom {
    position: fixed;
    z-index: 1025;
    bottom: 0;

    padding-top: 24px;
    padding-bottom: 24px;

    @include box-shadow-top();
  }
}