/*
 * CB Table Mixins
 */

@mixin table() {
  width: 100%;

  font-size: 1rem;
  line-height: 1.5em;
  color: $black1;

  /* Reset for nesting within parents with `background-color`. */
  background-color: transparent;

  > thead {
    color: $white;
    background: $gray1;

    > tr {
      > th {
        font-weight: 700;
      }
    }
  }

  th,
  td {
    min-width: 80px;
    padding: $table-cell-padding;
    vertical-align: top;
    border: $table-border-width solid $table-border-color;

    &[rowspan] {
      vertical-align: middle;
    }
  }

  &.cb-table-light {
    > thead {
      color: $black1;
      background: $white;

      > tr {
        > th,
        > td {
          padding: $table-cell-padding-light;
          border-top-width: 2px;
          border-bottom-width: 2px;
        }
      }
    }
  }

  th,
  td {
    min-width: 80px;
    padding: $table-cell-padding;
    vertical-align: top;
    border: $table-border-width solid $table-border-color;

    &[rowspan] {
      vertical-align: middle;
    }
  }

  &.cb-table-no-stretch {
    width: auto;
  }
}
