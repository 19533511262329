/*
 * CB Illustrations Core (Default)
 */

@import "apricot-variable";

.cb-ilus {
  @each $key, $value in $illustrations {
    &.cb-ilus-#{$key} {
      &::after {
        background-image: url("#{$illustrations-path}core/#{$key}.svg");
      }
    }
  }

  &.cb-ilus-shadow {
    &::before {
      background-image: url("#{$illustrations-path}core/shadow.svg");
    }
  }

  &.cb-ilus-circular {
    background-image: url("#{$illustrations-path}core/background.svg");
  }
}
