/*
 * CB Illustrations Mixins
 */

@mixin ilus-size($s1: 24px, $s2: 16px) {
&::after {
    top: calc(50% - (calc(100% - $s1) / 2));
    left: calc(50% - (calc(100% - $s2) / 2));
    width: calc(100% - $s2);
    height: calc(100% - $s2);
  }
}
