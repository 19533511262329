/*
 * CB Illustrations K-12
 */

@import "apricot-variable";

.cb-ilus-k-12 {
  @each $key, $value in $illustrations {
    &.cb-ilus-#{$key} {
      &::after {
        background-image: url("#{$illustrations-path}k-12/#{$key}.svg");
      }
    }
  }

  &.cb-ilus-shadow {
    &::before {
      background-image: url("#{$illustrations-path}k-12/shadow.svg");
    }
  }

  &.cb-ilus-circular {
    background-image: url("#{$illustrations-path}k-12/background.svg");
  }
}
