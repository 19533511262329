/*
 * CB Colors Mixins
 */

@mixin gradient-top($name, $color1, $color2) {
  .#{"cb-" + $name + "-gradient-top"} {
    background: $color1;
    background: linear-gradient(180deg, rgba($color2, 1) 0%, rgba($color1, 1) 20%);
  }
}

/* ------------------------------------ GRADIENT */

@mixin box-shadow-right() {
  box-shadow: 4px 0 4px 0 rgb(0 0 0 / 10%);
}

@mixin box-shadow-left() {
  box-shadow: -4px 0 4px 0 rgb(0 0 0 / 10%);
}

@mixin box-shadow-bottom() {
  box-shadow: 0 4px 4px 0 rgb(0 0 0 / 10%);
}

@mixin box-shadow-top() {
  box-shadow: 0 -4px 4px 0 rgb(0 0 0 / 10%);
}
