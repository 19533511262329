/*
 * CB Icons
 */

@use "sass:math";
@use "sass:map";
@use "sass:list";

@import "apricot-variable";
@import "../mixins/font";
@import "../mixins/glyph";
@import "../mixins/breakpoint";

@include font-face($icons-font-family-group-a, $icons-file-a, null, normal, woff ttf);
@include font-face($icons-font-family-group-b, $icons-file-b, null, normal, woff ttf);

/* ------------------------------------ ICONS */
.cb-icon {
  @each $group, $icons in $grouped-icons {
    @if list.index($grouped-icons-a, $group) {
      @each $icon, $value in $icons {
        &.cb-#{$icon} {
          @include glyph-font($icons-font-family-group-a);
          @include glyph-content($value);
        }
      }
    }

    @if list.index($grouped-icons-b, $group) {
      @each $icon, $value in $icons {
        &.cb-#{$icon} {
          @include glyph-font($icons-font-family-group-b);
          @include glyph-content($value);
        }
      }
    }
  }
}

.cb-icon {
  &.cb-search {
    background: none;
  }
}

/* ------------------------------------ NUMERIC */
.cb-icon-numeric {
  @include border-box();

  display: inline-block;

  width: 2em;
  height: 2em;

  font-size: 1rem;
  font-weight: 700;
  line-height: 1.82em !important;
  text-align: center;

  border: 0.125em solid $font-color-base;
  border-radius: 50%;

  &::before {
    content: attr(data-cb-number);
  }

  &.cb-icon-circular {
    color: $white;
    background-color: $blue5;
    border-color: $blue5;
  }
}

/* ------------------------------------ CIRCULAR */
.cb-icon-circular {
  &:not(.cb-icon-numeric) {
    position: relative;
    display: block;
    width: 2em;
    height: 2em;

    &::before {
      position: absolute;
      z-index: 2;
      top: math.div(1em, 2);
      left: math.div(1em, 2);

      color: $white;
    }

    &::after {
      content: " ";

      position: absolute;
      z-index: 1;

      display: block;

      width: 2em;
      height: 2em;

      background-color: $blue5;
      border-radius: 50%;
    }
  }
}

/* ------------------------------------ AVATAR */
.cb-icon-avatar {
  position: relative;

  display: block;

  width: 2em;
  height: 2em;

  font-size: 1rem;

  transition: all $transition-time-l ease;

  &::before {
    position: absolute;
    z-index: 2;
    top: math.div(0.5em, 2);
    left: math.div(0.5em, 2);

    font-size: 1.3125em;
    color: $white;
  }

  &::after {
    content: " ";

    position: absolute;
    z-index: 1;

    display: block;

    width: 2em;
    height: 2em;

    background-color: $black1;
    border-radius: 50%;
  }

  /* md up */
  @include media-breakpoint-up("md", $grid-breakpoints) {
    width: 3em;
    height: 3em;

    &::before {
      font-size: 2em;
    }

    &::after {
      width: 3em;
      height: 3em;
    }
  }
}

/* ------------------------------------ NEW SIZE PATTERN */
@each $size in $icon-size {
  .cb-icon {
    &.cb-icon-#{$size} {
      font-size: #{$size}px;
    }

    &.cb-icon-circular {
      $name: ($size * 2);

      &.cb-icon-#{$name} {
        font-size: #{$size}px;
      }
    }
  }
}

/* Per breakpoint */
@each $breakpoint, $value in $grid-breakpoints {
  @include media-breakpoint-only($breakpoint, $grid-breakpoints) {
    @each $size in $icon-size {
      .cb-icon-#{$breakpoint}-#{$size} {
        font-size: #{$size}px !important;
      }

      .cb-icon-circular {
        $name: ($size * 2);

        &.cb-icon-#{$breakpoint}-#{$name} {
          font-size: #{$size}px !important;
        }
      }
    }
  }

  /* UP */
  @include media-breakpoint-up($breakpoint, $grid-breakpoints) {
    @each $size in $icon-size {
      .cb-icon-#{$breakpoint}-up-#{$size} {
        font-size: #{$size}px !important;
      }

      .cb-icon-circular {
        $name: ($size * 2);

        &.cb-icon-#{$breakpoint}-up-#{$name} {
          font-size: #{$size}px !important;
        }
      }
    }
  }

  /* Down */
  @include media-breakpoint-down($breakpoint, $grid-breakpoints) {
    @each $size in $icon-size {
      .cb-icon-#{$breakpoint}-down-#{$size} {
        font-size: #{$size}px !important;
      }

      .cb-icon-circular {
        $name: ($size * 2);

        &.cb-icon-#{$breakpoint}-down-#{$name} {
          font-size: #{$size}px !important;
        }
      }
    }
  }
}

$num-icon-size-new: (12, 14, 16, 24, 32);

@each $size in $num-icon-size-new {
  .cb-icon-numeric {
    $name: ($size * 2);

    &.cb-icon-#{$name} {
      flex-shrink: 0;
      font-size: #{$size}px;
    }

    &.cb-icon-circular {
      $name: ($size * 2);

      &.cb-icon-#{$name} {
        flex-shrink: 0;
        font-size: #{$size}px;
      }
    }

    &.cb-icon-24 {
      line-height: 23px !important;
    }
  }
}

/* Numeric Per breakpoint */
@each $breakpoint, $value in $grid-breakpoints {
  @include media-breakpoint-only($breakpoint, $grid-breakpoints) {
    @each $size in $num-icon-size-new {
      .cb-icon-numeric {
        $name: ($size * 2);

        &.cb-icon-#{$breakpoint}-#{$name} {
          flex-shrink: 0;
          font-size: #{$size}px !important;
        }

        &.cb-icon-circular {
          $name: ($size * 2);

          &.cb-icon-#{$breakpoint}-#{$name} {
            flex-shrink: 0;
            font-size: #{$size}px !important;
          }
        }
      }
    }
  }

  /* UP */
  @include media-breakpoint-up($breakpoint, $grid-breakpoints) {
    @each $size in $num-icon-size-new {
      .cb-icon-numeric {
        $name: ($size * 2);

        &.cb-icon-#{$breakpoint}-up-#{$name} {
          flex-shrink: 0;
          font-size: #{$size}px !important;
        }

        &.cb-icon-circular {
          $name: ($size * 2);

          &.cb-icon-#{$breakpoint}-up-#{$name} {
            flex-shrink: 0;
            font-size: #{$size}px !important;
          }
        }
      }
    }
  }

  /* Down */
  @include media-breakpoint-down($breakpoint, $grid-breakpoints) {
    @each $size in $num-icon-size-new {
      .cb-icon-numeric {
        $name: ($size * 2);

        &.cb-icon-#{$breakpoint}-down-#{$name} {
          flex-shrink: 0;
          font-size: #{$size}px !important;
        }

        &.cb-icon-circular {
          $name: ($size * 2);

          &.cb-icon-#{$breakpoint}-down-#{$name} {
            flex-shrink: 0;
            font-size: #{$size}px !important;
          }
        }
      }
    }
  }
}

/* ------------------------------------ COLOR */

@each $color, $map in $color-themes {
  .cb-icon {
    &.#{"cb-" + $color + "-color"} {
      &.cb-icon-circular {
        &::after {
          background-color: map.get($map, code);
        }
      }
    }
  }

  .cb-icon-numeric {
    &.#{"cb-" + $color + "-color"} {
      border-color: map.get($map, code);

      &.cb-icon-circular {
        color: $white !important;
        background-color: map.get($map, code);
        border-color: map.get($map, code);
      }
    }
  }
}

@media print {
  @include print-icon-numeric();
}

.cb-print {
  @include print-icon-numeric();
}

/* ------------------------------------ CUSTOM */
.cb-play-video {
  font-size: 4rem;
}
