/*
 * CB Sticky
 */

@import "apricot-variable";

.cb-sticky {
  &.cb-sticky-active {
    position: sticky;
    z-index: 900;
    left: 0;

    &.cb-sticky-top {
      top: 0;
    }

    &.cb-sticky-bottom {
      bottom: 0;
    }
  }
}
