/*
 * CB Photo Gallery
 */

@import "apricot-variable";
@import "../mixins/breakpoint";
@import "../mixins/button";
@import "../mixins/photo-gallery";

$thumb-margin-lr: 24px;
$thumb-margin-tb: 48px;

/* ------------------------------------ THUMBNAILS */

.cb-photo-gallery {
  ul {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;

    margin: 0;
    margin-top: -#{$thumb-margin-tb};
    margin-left: -#{$thumb-margin-lr};
    padding: 0;

    li {
      flex-shrink: 0;

      margin: 0;
      margin-top: $thumb-margin-tb;
      margin-left: $thumb-margin-lr;

      list-style: none;

      .cb-photo-thumb {
        img {
          overflow: hidden;
          opacity: 1;
          transition: opacity 200ms;

          &:not([data-cb-lazy]) {
            width: 100%;
            height: auto;
            max-height: 100%;
          }

          &.cb-active-effect {
            width: 100%;
            height: auto;
            max-height: 100%;
          }

          @media (prefers-reduced-motion: reduce) {
            transition: none;
          }
        }

        + .cb-photo-thumb-info {
          margin-top: 16px;
        }
      }

      .cb-photo-thumb-info {
        h2,
        .cb-h2,
        h3,
        .cb-h3,
        h4,
        .cb-h4,
        h5,
        .cb-h5 {
          /* md and down 19 */
          margin: 0;

          /* 21 */
          font-size: 1.3125rem;
          font-weight: 500;

          /* 24 */
          line-height: 1.1429em;
          color: $font-color-base;

          @include media-breakpoint-down("md", $grid-breakpoints) {
            font-size: $font-size-md;
            line-height: $line-height-md;
          }

          + p {
            margin-top: 4px;
          }
        }

        p {
          display: none;
          color: $font-color-base;

          &:first-of-type {
            display: block;
            margin-bottom: 0;
          }
        }
      }

      a {
        color: $font-color-base;
        text-decoration: none;

        &:hover,
        &:active,
        &.cb-focus {
          * {
            color: $font-color-base;
            text-decoration: underline;
          }
        }

        &.cb-focus {
          cursor: pointer;
          text-decoration: underline;

          img {
            opacity: 0.7;
          }
        }
      }

      .cb-photo-details-info {
        display: none;
        visibility: hidden;
      }
    }

    &.cb-photo-gallery-grid {
      margin-right: -12px;
      margin-left: -12px;

      li {
        margin-top: $thumb-margin-tb;
        margin-left: 0;
      }
    }

    /* ------------------------------------ ITEM WIDTH CALCULATION */

    &.cb-photo-gallery-5 {
      /* 5 items */
      @include cb-photo-gallery-item(5, 8, $thumb-margin-lr);
    }

    &.cb-photo-gallery-4 {
      /* 4 items */
      @include cb-photo-gallery-item(4, 6, $thumb-margin-lr);
    }

    &.cb-photo-gallery-3 {
      /* 3 items */
      @include cb-photo-gallery-item(3, 4, $thumb-margin-lr);
    }

    &.cb-photo-gallery-2 {
      /* 2 items */
      @include cb-photo-gallery-item(2, 2, $thumb-margin-lr);

      .cb-photo-thumb {
        height: 144px;
      }
    }

    &.cb-photo-gallery-1 {
      /* 1 items */
      li {
        width: 100%;
        margin-right: 0;
        margin-left: 0;

        .cb-photo-thumb {
          height: 144px;
        }
      }
    }

    @include media-breakpoint-only("xs", $grid-breakpoints) {
      flex-direction: column;
      padding: 0 0 0 24px;

      li {
        width: 100% !important;
        margin-right: 0;
        margin-left: 0;

        .cb-photo-thumb {
          height: auto !important;

          img {
            width: 100%;
            max-width: auto;
          }
        }
      }
    }
  }

  &.cb-no-desc {
    ul {
      margin-top: -#{$thumb-margin-lr};

      li {
        margin-top: $thumb-margin-lr;
      }
    }
  }
}

/* ------------------------------------ MODAL */

.cb-photo-gallery-modal {
  .cb-modal-container {
    position: relative;
    display: flex;
    flex-direction: column;

    .cb-modal-content {
      height: calc(100vh - 141px) !important;
      margin-top: 141px;
      padding-bottom: 141px;
      background-color: $black1;
    }

    .cb-gallery-header {
      position: absolute !important;
      z-index: 999;
      top: 0;
      left: 0;

      display: flex;
      align-items: center;
      justify-content: space-between;

      width: 100%;
      height: 141px;
      margin-bottom: 0;
      padding: 0 41px;

      color: $white;

      background-color: $black1;

      .cb-gallery-counter {
        text-align: center;

        &.cb-btn-3 {
          width: calc(100% - ((3 * 32px) + 32px));
          padding-left: calc((3 * 32px) + 32px);
        }

        &.cb-btn-2 {
          width: calc(100% - ((2 * 32px) + 16px));
          padding-left: calc((2 * 32px) + 16px);
        }

        &.cb-btn-1 {
          width: calc(100% - 32px);
          padding-left: 32px;
        }

        .cb-count-info {
          display: inline-flex;
          margin-bottom: 0;
          font-size: $font-size-small;

          span {
            &:nth-of-type(2) {
              display: inline-block;
              width: 10px;
              margin-right: 6px;
              margin-left: 6px;
            }
          }
        }
      }

      .cb-gallery-controls {
        .cb-btn {
          position: relative;

          @include close();

          + .cb-btn {
            margin-left: 16px;
          }
        }
      }
    }

    .cb-gallery-container {
      display: flex;
      width: 100%;
      height: 100%;
      background-color: $black1;

      @include media-breakpoint-down("sm", $grid-breakpoints) {
        overflow-y: auto;
        height: calc(100vh - 71px) !important;
      }

      /* left/right arrow */
      .cb-gallery-navigation {
        display: flex;
        align-items: center;
        justify-content: center;

        width: 144px;

        transition: width $transition-time-s ease-in-out;

        span {
          width: 0.5em;
          height: 1em;

          font-size: 3rem;
          color: $white;

          opacity: 0.5;

          transition: opacity 300ms;

          @include media-breakpoint-down("sm", $grid-breakpoints) {
            font-size: 1.5rem;
          }

          @media (prefers-reduced-motion: reduce) {
            transition: none;
          }
        }

        &:hover,
        &.hover {
          cursor: pointer;

          span {
            opacity: 1;
          }
        }

        @media (prefers-reduced-motion: reduce) {
          transition: none;
        }

        @include media-breakpoint-only("md", $grid-breakpoints) {
          width: 96px;
        }
      }

      .cb-gallery-content {
        position: relative;
        display: flex;
        width: calc(100% - 288px);

        @include media-breakpoint-only("md", $grid-breakpoints) {
          width: calc(100% - 192px);
        }

        @include media-breakpoint-up("md", $grid-breakpoints) {
          justify-content: center;
        }

        .cb-gallery-image {
          position: relative;

          width: calc(100% * (2 / 3));
          height: inherit;

          background-color: $white;

          transition: width $transition-time-s ease-in-out;

          .cb-gallery-image-container {
            display: flex;
            align-items: center;
            justify-content: center;

            width: 100%;
            height: 100%;

            img {
              transition: opacity $transition-time-s ease-in-out;

              @media (prefers-reduced-motion: reduce) {
                transition: none;
              }
            }

            &.cb-has-fullscreen {
              img {
                &:hover {
                  cursor: zoom-in;
                }
              }
            }

            &.cb-hide-effect {
              img {
                opacity: 0;
              }
            }

            &.cb-active-effect {
              img {
                opacity: 1;
              }
            }
          }

          @media (prefers-reduced-motion: reduce) {
            transition: none;
          }
        }

        @include media-breakpoint-up("md", $grid-breakpoints) {
          &.cb-image-padding {
            &:not(.cb-no-info) {
              .cb-gallery-image {
                .cb-gallery-image-container {
                  padding: 24px 0 24px 24px;
                }
              }
            }

            &.cb-no-info {
              .cb-gallery-image {
                background: transparent;
              }
            }
          }
        }

        .cb-info-container {
          display: flex;
          flex-direction: column;

          width: calc(100% * (1 / 3));
          min-height: 96px;
          padding: 48px;

          background-color: $white;

          @include media-breakpoint-only("md", $grid-breakpoints) {
            padding: 24px;
          }

          .cb-gallery-des {
            overflow-y: auto;
            width: 100%;

            h4,
            h5 {
              margin: 0;
              font-size: 1rem;
              line-height: 1.5em;
            }

            .cb-main-title {
              margin: 0;
              padding: 0;
              font-size: 1.188rem;
              line-height: 30px;
            }

            .cb-main-info {
              margin: 0;
              padding: 0;
              color: $font-size-base;
            }

            .cb-sub-title {
              margin: 0.75em 0 0.375em;
              margin-top: 30px;

              font-size: 1rem;
              font-weight: 500;
              line-height: 1em;
            }
          }

          &.cb-no-info {
            display: none;
          }
        }
      }
    }

    /* mobile & tablet */
    @include media-breakpoint-down("sm", $grid-breakpoints) {
      .cb-gallery-header {
        width: 100%;
        height: 71px;
        padding: 0 24px;

        .cb-gallery-counter {
          display: flex;
          padding-left: 0 !important;
          text-align: left;
        }
      }

      .cb-modal-content {
        position: relative;
        height: calc(100vh - 71px) !important;
        margin-top: 71px;
        padding-bottom: 0;

        .cb-gallery-container {
          height: auto;

          .cb-gallery-navigation {
            width: 24px;
          }

          .cb-gallery-content {
            overflow-y: auto;
            flex-direction: column;
            justify-content: top;

            width: calc(100% - 48px);
            height: 100%;

            .cb-gallery-image {
              position: relative;
              width: 100%;
              height: auto;

              .cb-gallery-image-container {
                display: flex;
                align-items: center;
                justify-content: center;
                height: auto;
              }

              .cb-gallery-hit {
                position: absolute;
                inset: 0;
              }
            }

            .cb-info-container {
              flex: 1 1 auto;
              width: 100%;
              min-height: fit-content;
              padding: 24px;
            }
          }
        }
      }
    }
  }

  &.cb-no-desc {
    .cb-modal-container {
      .cb-gallery-container {
        .cb-gallery-content {
          width: 100%;

          .cb-gallery-image {
            width: 100%;
            background-color: transparent;
          }

          .cb-info-container {
            display: none;
          }

          @include media-breakpoint-down("sm", $grid-breakpoints) {
            .cb-gallery-image {
              .cb-gallery-image-container {
                height: calc(100vh - 71px);
              }
            }
          }
        }
      }
    }
  }

  &.cb-single-img {
    .cb-modal-container {
      .cb-gallery-header {
        .cb-gallery-controls {
          margin-left: auto;

          .cb-btn-play {
            display: none;
          }
        }

        .cb-gallery-counter {
          display: none;
        }
      }

      .cb-gallery-container {
        .cb-gallery-content {
          width: 100%;

          @include media-breakpoint-down("sm", $grid-breakpoints) {
            .cb-gallery-image {
              &::after {
                padding-top: calc(100% - 41px);
              }
            }
          }
        }
      }
    }
  }

  &.cb-photo-full {
    .cb-modal-container {
      .cb-gallery-container {
        .cb-gallery-content {
          .cb-gallery-image {
            .cb-gallery-image-container {
              &.cb-has-fullscreen {
                img {
                  &:hover {
                    cursor: zoom-out !important;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
