/*
 * CB Floating Label
 */

.cb-floating-label {
  position: relative;

  label {
    position: absolute;
    z-index: 2;
    top: 12px;
    left: 16px;

    line-height: 1.5em;

    background-color: $white;

    transition-timing-function: linear;
    transition-duration: 0.1s;
    transition-property: top, font-size;

    &.cb-required {
      &::after {
        margin-left: 2px;
        padding: 0 2px 0 4px;
        background-color: $white;
      }
    }

    &.cb-value-fl,
    &.cb-focus-fl,
    &.cb-fill-fl {
      top: -8px;
      left: 14px;

      padding: 0 2px;

      font-size: $font-size-x-small;
      font-weight: 500;
      line-height: 1.3333em;
      color: $gray1;

      background-color: $white;
    }

    &.cb-focus-fl {
      font-weight: 500;
      color: $input-focus;
    }
  }

  .cb-input-icon-left {
    label {
      left: 39px;

      &.cb-value-fl,
      &.cb-focus-fl {
        left: 14px;
      }
    }

    .cb-glyph,
    .cb-icon {
      top: 12px;
    }
  }

  .cb-input-icon-right {
    .cb-glyph,
    .cb-icon {
      top: 12px;
    }
  }

  /* ------------------------------------ CONDENSED */
  &.cb-input-condensed,
  &.cb-condensed {
    label {
      top: 9px;

      &.cb-value-fl,
      &.cb-focus-fl {
        top: -8px;
        left: 14px;
      }
    }

    .cb-input-icon-left {
      .cb-glyph,
      .cb-icon {
        top: 9px;
        left: 16px;
      }
    }

    .cb-input-icon-right {
      .cb-glyph,
      .cb-icon {
        top: 9px;
      }
    }
  }

  /* ------------------------------------ BUTTON INPUT */
  &.cb-btn-input {
    .cb-btn {
      top: 8px;
    }
  }

  /* autofill */
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    animation-name: on-auto-fill-start-floating;
    animation-duration: 2000s;
    animation-iteration-count: 1;
  }
}

.cb-floating-label {
  &.cb-not-active {
    label {
      position: relative;
      top: 0;
      left: 0;
    }
  }
}

@keyframes on-auto-fill-start-floating {
  from {
    font-size: inherit;
  }

  to {
    font-size: inherit;
  }
}

/* ------------------------------------ CUSTOM COLOR */
.cb-floating-label {
  label {
    &.cb-dark-bg {
      background: transparent !important;

      &.cb-value-fl,
      &.cb-focus-fl,
      &.cb-fill-fl {
        top: -18px;
        color: $white;
      }
    }

    &.cb-light-bg {
      background: transparent !important;

      &.cb-value-fl,
      &.cb-focus-fl,
      &.cb-fill-fl {
        top: -18px;
        color: $black1;
      }
    }

    &.cb-required {
      &::after {
        background-color: transparent;
      }
    }
  }
}
