/*
 * CB Spinner
 */

@use "sass:color";

@import "apricot-variable";

$deg: 30;

/* All high contrast styling rules */
@mixin ms-contrast() {
  .cb-spinner {
    > *:not(p) {
      &::before {
        background-color: transparent !important;
      }
    }

    .ms-high-contrast {
      display: flex;
      align-items: center;
      justify-content: center;

      width: 100%;
      height: 100%;
      margin: 0;

      /* 14 */
      font-size: $font-size-small; 
      font-weight: 500;

      /* 24 */
      line-height: $line-height-small; 
      color: $black1;

      + .cb-spinner-indicator {
        margin-top: 8px;
      }
    }
  }
}

.cb-spinner {
  position: relative;
  width: 4rem;
  height: 4rem;

  > *:not(p) {
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    &::before {
      content: "";

      display: block;

      width: 12%;
      height: 12%;
      margin: 0 auto;

      background-color: $black1;
      border-radius: 100%;

      animation: spinner-bounce-delay 1.2s infinite ease-in-out both;

      @media (prefers-reduced-motion: reduce) {
        animation: none;
      }
    }

    &:nth-child(2) {
      transform: rotate(30deg);

      &::before {
        animation-delay: -1.1s;
      }
    }

    &:nth-child(3) {
      transform: rotate(60deg);

      &::before {
        animation-delay: -1s;
      }
    }

    &:nth-child(4) {
      transform: rotate(90deg);

      &::before {
        animation-delay: -0.9s;
      }
    }

    &:nth-child(5) {
      transform: rotate(120deg);

      &::before {
        animation-delay: -0.8s;
      }
    }

    &:nth-child(6) {
      transform: rotate(150deg);

      &::before {
        animation-delay: -0.7s;
      }
    }

    &:nth-child(7) {
      transform: rotate(180deg);

      &::before {
        animation-delay: -0.6s;
      }
    }

    &:nth-child(8) {
      transform: rotate(210deg);

      &::before {
        animation-delay: -0.5s;
      }
    }

    &:nth-child(9) {
      transform: rotate(240deg);

      &::before {
        animation-delay: -0.4s;
      }
    }

    &:nth-child(10) {
      transform: rotate(270deg);

      &::before {
        animation-delay: -0.3s;
      }
    }

    &:nth-child(11) {
      transform: rotate(300deg);

      &::before {
        animation-delay: -0.2s;
      }
    }

    &:nth-child(12) {
      transform: rotate(330deg);

      &::before {
        animation-delay: -0.1s;
      }
    }
  }

  p {
    margin-left: 11px;
    font-size: $font-size-x-small;
    line-height: 64px;
  }

  &.cb-spinner-light {
    > * {
      &::before {
        background-color: $white;
      }
    }
  }

  @each $key, $value in $palette-colors {
    &.cb-palette-#{$key} {
      > *:not(p) {
        &::before {
          background-color: $value;
        }
      }

      .cb-spinner-indicator {
        color: $value;
      }
    }
  }
}

  /* ------------------------------------ SIZE */

$spinner-size: (16, 24, 32, 64);

@each $size in $spinner-size {
  .cb-spinner {
    &.cb-spinner-#{$size} {
      width: #{$size}px;
      height: #{$size}px;
    }
  }
}

  /* ------------------------------------ LABEL */
  .cb-spinner,
.cb-progress-spinner {
  .cb-spinner-indicator {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 100%;
    margin: 0;

    /* 14 */
    font-size: $font-size-small; 
    font-weight: 500;

    /* 24 */
    line-height: $line-height-small; 
    color: $black1;
  }
}

.cb-progress-spinner {
  position: relative;
  width: 4rem;
  height: 4rem;

  div {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 100%;
  }

  .cb-spinner-indicator {
    position: absolute;
    top: 8px;
    left: 8px;

    width: calc(100% - 16px);
    height: calc(100% - 16px);

    background-color: $white;
    border-radius: 50%;
  }

  .cb-spinner-background {
    content: "";

    position: absolute;

    width: 100%;
    height: 100%;

    background-color: $gray4;
    border-radius: 100%;
  }

  .cb-spinner-rotate {
    background-color: $black1;
    clip: rect(0 32px 4rem 0);
  }

  .cb-spinner-left {
    opacity: 1;
    background-color: $gray4;
    clip: rect(0 32px 64px 0);
    box-shadow: 0 0 0 2px $white;
  }

  .cb-spinner-right {
    transform: rotate(180deg);
    opacity: 0;
    background-color: $black1;
    clip: rect(0 32px 64px 0);
  }

  &.cb-spinner-light {
    .cb-spinner-background,
    .cb-spinner-left {
      background-color: color.mix($black, $white, 50%);
      box-shadow: none;
    }

    .cb-spinner-rotate,
    .cb-spinner-right {
      background-color: $white;
    }

    .cb-spinner-indicator {
      color: $white;
    }
  }

  @each $key, $value in $palette-colors {
    &.cb-palette-#{$key} {
      .cb-spinner-background,
      .cb-spinner-left {
        background-color: color.mix($white, $value, 90%);
      }

      .cb-spinner-rotate,
      .cb-spinner-right {
        background-color: $value;
      }

      .cb-spinner-indicator {
        color: $value;
      }
    }

    &.cb-palette-blue5 {
      &::before {
        background-color: $blue5-tint1;
      }

      .cb-spinner-left {
        background-color: $blue5-tint1;
      }
    }
  }
}

@media screen and (-ms-high-contrast: active) {
  @include ms-contrast();
}

@media (forced-colors: active) {
  @include ms-contrast();
}

/* spinner */
@keyframes spinner-bounce-delay {
  0%,
  80%,
  100% {
    transform: scale(0);
  }

  40% {
    transform: scale(1);
  }
}

@keyframes spinner-bounce-delay {
  0%,
  80%,
  100% {
    transform: scale(0);
  }

  40% {
    transform: scale(1);
  }
}

/* progress */
@keyframes toggle {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}