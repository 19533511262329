/*
 * CB Forms Validation
 */

.cb-validation-state,
.cb-validation-success,
.cb-validation-error {
  display: flex;
  flex-wrap: nowrap;
  align-items: flex-end;

  &.cb-textarea {
    align-items: flex-start;
  }

  .cb-input-icon-right,
  .cb-input-icon-left,
  .cb-validation-label-input {
    position: relative;
    flex-grow: 1;
  }

  .cb-validation-icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;

    width: 3rem;
    height: 3rem;

    line-height: 1em;
  }

  &.cb-input-condensed,
  &.cb-condensed {
    .cb-validation-icon {
      width: 3rem;
      height: 2.5rem;
      font-size: 1rem;
    }
  }
}

fieldset {
  &.cb-validation-state,
  &.cb-validation-success,
  &.cb-validation-error {
    flex-direction: column;
    align-items: flex-start;
  }
}

.cb-validation-success {
  .cb-validation-icon {
    &.cb-glyph,
    &.cb-icon {
      color: $success-color;
    }
  }
}

.cb-validation-success {
  .cb-input-helper,
  &.cb-input-helper {
    align-items: start;

    &::before {
      content: "\e951";

      margin-right: 8px;

      font-size: 1rem;
      line-height: 1em;
      color: $gray4;

      @include glyph-font($icons-font-family-group-a);
    }
  }
}

.cb-validation-error {
  @include form-control-validation($error-color, $error-color);

  label {
    &.cb-value-fl,
    &.cb-focus-fl {
      color: $error-color;
    }
  }

  .cb-input-helper,
  &.cb-input-helper {
    align-items: start;

    &::before {
      content: "\e952";

      margin-right: 8px;

      font-size: 1rem;
      line-height: 1em;
      color: $error-color;

      @include glyph-font($icons-font-family-group-a);
    }
  }
}

.cb-validation-error {
  .cb-validation-icon {
    &.cb-glyph,
    &.cb-icon {
      color: $error-color;
    }
  }
}

.cb-input-helper-block {
  /* backward compatibility */
  &:not(.cb-notification) {
    width: calc(100% - 48px);
    transition: height $transition-time-l ease;

    ul {
      margin: 0;
      padding: 0;

      list-style: none;

      opacity: 0;

      animation-duration: 0.5s;
      animation-timing-function: ease-in-out;
      animation-fill-mode: forwards;

      li {
        &.cb-input-helper {
          display: flex;
          flex-wrap: nowrap;
          align-items: flex-start;

          &::before {
            transition: all $transition-time-l ease-in-out;
          }

          p,
          span {
            font-size: $font-size-x-small;
            line-height: 1.3333em;
            color: $gray1;
          }

          &:not(.cb-validation-error):not(.cb-validation-success) {
            &::before {
              content: "\e951";

              margin-right: 8px;

              font-size: 1rem;
              line-height: 1em;
              color: $gray4;

              @include glyph-font($icons-font-family-group-a);
            }
          }

          &.cb-validation-success {
            &::before {
              @include glyph-font($icons-font-family-group-a);

              content: "\e951";

              animation-name: valid-icon;
              animation-duration: 0.25s;
              animation-timing-function: ease-in-out;
              animation-fill-mode: forwards;
            }
          }

          &.cb-validation-error {
            &::before {
              @include glyph-font($icons-font-family-group-a);

              content: "\e952";
              color: $gray4;
            }

            p,
            span {
              color: $red1;
            }
          }
        }
      }
    }

    &.cb-show-err-state {
      ul {
        li {
          &.cb-input-helper {
            &.cb-validation-error {
              &::before {
                @include glyph-font($icons-font-family-group-a);

                content: "\e952";
                color: $error-color;
              }
            }
          }
        }
      }
    }

    &.cb-hidden {
      overflow-y: hidden;
      height: 0;

      ul {
        animation-name: fade-out;
      }
    }

    &.cb-show {
      overflow-y: auto;
      height: auto;

      ul {
        animation-name: fade-in;
      }
    }
  }

  /* Error Block Display
   * backward compatibility
   */ 
  &.cb-notification {
    ul {
      li {
        a {
          &.cb-input-helper {
            display: block;
            font-size: 1rem;
            line-height: 1.5em;

            &::before {
              content: "";
              margin-right: 0;
            }

            &.cb-validation-success {
              &::before {
                content: "";
                margin-right: 0;
              }
            }

            &.cb-validation-error {
              &::before {
                content: "";
                margin-right: 0;
              }
            }
          }
        }
      }
    }
  }
}

@keyframes valid-icon {
  from {
    content: "\e951";
    color: $success-color;
  }

  to {
    content: "\e951";
    color: $gray4;
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fade-out {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}
