/*
 * CB Tabs
 */

@import "apricot-variable";
@import "../mixins/menu-list";

.cb-tabs-menu {
  display: flex;
  height: 72px;

  ul {
    display: flex;

    height: 100%;
    margin: 0;
    padding: 0;

    list-style: none;

    li {
      list-style: none;

      a {
        text-decoration: none !important;

        @include horizontal-menu-link();

        &.cb-disabled,
        &:disabled {
          cursor: not-allowed;

          color: $gray3;
          text-decoration: none !important;

          opacity: 0.5;
          background-color: $gray5;
          border-color: $gray5;
        }
      }
    }
  }

  &.cb-condensed {
    height: 48px;

    ul {
      li {
        a {
          @include horizontal-menu-link(1);
        }
      }
    }
  }

  &.cb-tabs-menu-icon {
    height: auto;

    ul {
      li {
        a {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: flex-end;

          [class^="cb-glyph"],
          [class^="cb-icon"] {
            margin-top: 10px;
            margin-bottom: 8px;
            font-size: 26px;
          }
        }
      }
    }

    &.cb-condensed {
      height: auto;

      ul {
        li {
          a {
            [class^="cb-glyph"],
            [class^="cb-icon"] {
              margin-top: 16px;
              margin-bottom: 8px;
              font-size: 1rem;
            }
          }
        }
      }
    }

    &.cb-tabs-menu-icon-only {
      ul {
        li {
          a {
            padding: 0;

            [class^="cb-glyph"],
            [class^="cb-icon"] {
              margin: 1rem;
              font-size: 1.5rem;
            }
          }
        }
      }
    }
  }

  & + .cb-tabs-container {
    margin-top: 48px;
  }
}

.cb-tabs-container {
  .cb-tabs-content {
    display: none;

    &.cb-selected {
      display: block;
    }
  }
}

/* ------------------------------------ KEYBOARD FOCUS */
[data-cb-input-m="keyboard"] {
  .cb-tabs-menu {
    a {
      &.cb-menu-link {
        &:focus,
        &.cb-focus {
          background-color: $gray5;
          outline: auto !important;
          outline-color: $outline-color !important;
        }
      }
    }
  }
}
