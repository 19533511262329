/* -----------------------------
 * CB Colors
 * -----------------------------
 */

@use "sass:map";
@use "sass:list";

@each $color, $map in $color-themes {
  @each $type, $prop in $color-types {
    $valid: map.get($map, $type);

    @if $valid == 1 {
      .#{"cb-" + $color + "-" + $type} {
        #{$prop}: map.get($map, code) !important;
      }
    }
  }
}

/* ------------------------------------ ACCENT */
@each $color, $accents in $accent-colors {
  @for $i from 1 through list.length($accents) {
    .cb-#{$color}-accent-#{$i} {
      background-color: list.nth($accents, $i);
    }
  }
}

.cb-yellow-bg {
  background-color: $yellow !important;
}

/* ------------------------------------ SUCCESS */
.cb-success-bg {
  background-color: $success !important;
}

.cb-success-shade-1 {
  background-color: $success-shade1 !important;
}

.cb-success-shade-2 {
  background-color: $success-shade2 !important;
}

.cb-success-tint-1 {
  background-color: $success-tint1 !important;
}

.cb-success-tint-2 {
  background-color: $success-tint2 !important;
}

/* ------------------------------------ WARNING */
.cb-warning-bg {
  background-color: $warning !important;
}

.cb-warning-shade-1 {
  background-color: $warning-shade1 !important;
}

.cb-warning-shade-2 {
  background-color: $warning-shade2 !important;
}

.cb-warning-tint-1 {
  background-color: $warning-tint1 !important;
}

.cb-warning-tint-2 {
  background-color: $warning-tint2 !important;
}

/* ------------------------------------ CAUTION */
.cb-caution-bg {
  background-color: $caution !important;
}

.cb-caution-shade-1 {
  background-color: $caution-shade1 !important;
}

.cb-caution-shade-2 {
  background-color: $caution-shade2 !important;
}

.cb-caution-tint-1 {
  background-color: $caution-tint1 !important;
}

.cb-caution-tint-2 {
  background-color: $caution-tint2 !important;
}

/* ------------------------------------ GRADIENT EFFECTS */
.cb-gray-gradient,
.cb-gray-gradient-bottom {
  background: $gray4;
  background: linear-gradient(0deg, $gray4 0, $gray5 20%, $gray5 100%);
}

@include gradient-top(white, $white, $gray5);
@include gradient-top(gray, $gray5, $gray4);
@include gradient-top(gray1, $gray1, #404040);
@include gradient-top(blue1, $blue1, #015685);
@include gradient-top(blue2, $blue2, #0069b1);
@include gradient-top(blue3, $blue3, #0085c8);
@include gradient-top(purple1, $purple1, #632a79);
@include gradient-top(green1, $green1, #348339);

.cb-blue5-gradient-top,
.cb-blue-gradient-top {
  background: #28369a;
  background-image: linear-gradient(to bottom, #28369a, #324dc7);
}

/* ------------------------------------ COLOR PER BREAKPOINT */

/* Per breakpoint */
@each $breakpoint, $value in $grid-breakpoints {
  @include media-breakpoint-only($breakpoint, $grid-breakpoints) {
    .cb-white-bg-#{$breakpoint} {
      background-color: $white !important;
    }
  }

  @include media-breakpoint-up($breakpoint, $grid-breakpoints) {
    .cb-white-bg-#{$breakpoint}-up {
      background-color: $white !important;
    }
  }

  @include media-breakpoint-down($breakpoint, $grid-breakpoints) {
    .cb-white-bg-#{$breakpoint}-down {
      background-color: $white !important;
    }
  }
}

.cb-no-bg {
  background: unset !important;
}
