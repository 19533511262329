/*
 * CB Clear Input
 */

.cb-clear-input {
  position: relative;

  .cb-btn {
    display: none;
  }

  &.cb-validation-success-ci {
    .cb-glyph,
    .cb-icon {
      &.cb-check {
        color: transparent;
      }
    }
  }

  &.cb-value-ci {
    &:not(.cb-focus-elem-ci) {
      &:not(.cb-focus-btn-ci) {
        &.cb-validation-success-ci {
          .cb-glyph,
          .cb-icon {
            &.cb-check {
              color: $success-color;
            }
          }
        }
      }
    }
  }

  &.cb-focus-elem-ci,
  &.cb-focus-btn-ci {
    &.cb-value-ci {
      .cb-input-icon-right {
        .cb-glyph,
        .cb-icon {
          display: none;
        }

        .cb-btn {
          .cb-glyph,
          .cb-icon {
            display: block;
          }
        }
      }

      .cb-btn {
        display: block;
      }
    }
  }
}
