/*
 * CB Modals
 */

@import "apricot-variable";
@import "../mixins/breakpoint";
@import "../mixins/font";
@import "../mixins/modal";

.cb-modal {
  display: none;

  &.cb-open {
    display: block;
  }

  &[aria-hidden="false"] {
    .cb-modal-overlay {
      animation: fade-in 0.3s cubic-bezier(0, 0, 0.2, 1);
    }

    .cb-modal-container {
      animation: slide-in 0.3s cubic-bezier(0, 0, 0.2, 1);
    }

    &.cb-no-animation-open {
      .cb-modal-overlay {
        animation: fade-in-motion 0.3s cubic-bezier(0, 0, 0.2, 1);
      }

      .cb-modal-container {
        animation: slide-in-motion 0.3s cubic-bezier(0, 0, 0.2, 1);
      }
    }

    /* A11Y */
    @media (prefers-reduced-motion: reduce) {
      .cb-modal-overlay {
        animation: fade-in-motion 0.3s cubic-bezier(0, 0, 0.2, 1);
      }

      .cb-modal-container {
        animation: slide-in-motion 0.3s cubic-bezier(0, 0, 0.2, 1);
      }
    }
  }

  &[aria-hidden="true"] {
    .cb-modal-overlay {
      animation: fade-out 0.3s cubic-bezier(0, 0, 0.2, 1);
    }

    .cb-modal-container {
      animation: slide-out 0.3s cubic-bezier(0, 0, 0.2, 1);
    }

    &.cb-no-animation-close {
      .cb-modal-overlay {
        animation: fade-out-motion 0.3s cubic-bezier(0, 0, 0.2, 1);
      }

      .cb-modal-container {
        animation: slide-out-motion 0.3s cubic-bezier(0, 0, 0.2, 1);
      }
    }

    /* A11Y */
    @media (prefers-reduced-motion: reduce) {
      .cb-modal-overlay {
        animation: fade-out-motion 0.3s cubic-bezier(0, 0, 0.2, 1);
      }

      .cb-modal-container {
        animation: slide-out-motion 0.3s cubic-bezier(0, 0, 0.2, 1);
      }
    }
  }

  .cb-modal-container {
    box-sizing: border-box;
    width: 900px;
    max-width: calc(100vw - 48px);
    max-height: calc(100vh - 48px);
    padding: $modal-spacing;

    background-color: $modal-bg;
    border-radius: $modal-border-radius;

    /* desktop */
    @include media-breakpoint-down("md", $grid-breakpoints) {
      width: 600px;
    }

    /* mobile */
    @include media-breakpoint-down("xs", $grid-breakpoints) {
      width: auto;
    }

    &:not(.cb-modal-overflow) {
      overflow: hidden;
    }

    /* mobile */
    @include media-breakpoint-down("xs", $grid-breakpoints) {
      margin: 12px;
    }

    .cb-modal-header {
      position: relative;

      display: flex;
      align-items: center;

      min-height: 30px;
      margin-bottom: 24px;

      .cb-modal-title {
        @include modal-title();
      }

      &.cb-modal-title-center {
        .cb-modal-title {
          width: 100%;
          text-align: center;
        }
      }

      &.cb-modal-has-close {
        .cb-modal-title {
          max-width: calc(100% - 40px);
        }

        &.cb-modal-title-center {
          .cb-modal-title {
            width: calc(100% - 40px);
            padding-left: 40px;
          }
        }
      }

      .cb-btn-close {
        position: absolute;
        top: 0;
        right: 0;
      }
    }

    .cb-modal-content {
      margin-bottom: 24px;
    }

    &.cb-modal-overflow {
      .cb-modal-content {
        overflow-y: unset !important;
      }
    }

    .cb-modal-footer {
      display: flex;
      justify-content: flex-end;
    }
  }

  &.cb-dialog-modal {
    .cb-modal-container {
      padding: 0;

      .cb-modal-content {
        overflow: hidden !important;
        height: auto !important;
        margin-bottom: 0;
      }

      .cb-notification {
        margin-bottom: 0;
        padding: 0;
        border: 0;

        .cb-notification-container {
          .cb-notification-title {
            
            @include modal-title();
          }

          .cb-btn.cb-btn-close {
            margin-top: 0;
            margin-right: 0;
          }
        }
      }
    }
  }

  &.cb-video-modal {
    .cb-modal-container {
      padding: 0;
      background-color: grey;

      .cb-modal-header {
        position: fixed;
        top: 24px;
        right: 24px;
        margin-bottom: 0;
      }

      .cb-modal-content {
        position: relative;
        width: 100%;
        height: 100%;
        margin: 0;

        iframe {
          position: absolute;
          top: 0;
          left: 0;

          width: 100%;
          height: 100%;

          border: 0;
        }
      }
    }
  }

  &.cb-spinner-modal {
    .cb-modal-container {
      display: flex;
      align-items: center;
      justify-content: center;

      width: 100px;
      max-width: 100px;
      min-height: 100px;
      padding: 0 !important;

      background-color: transparent !important;
      outline: 0 !important;

      .cb-modal-content {
        overflow: unset !important;
        height: 100% !important;
        margin: 0 !important;
      }
    }
  }

  &.cb-photo-gallery-modal {
    .cb-modal-container {
      padding: 0;
    }
  }

  &.cb-promo-modal {
    .cb-modal-container {
      display: flex;
      padding: 0 !important;
      background-color: transparent !important;

      .cb-promo-modal-container {
        height: 100%;
        padding: 24px;
        background-color: $white;
      }

      .cb-promo-modal-img {
        width: 100%;
        height: 100%;

        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;

        &.cb-img-contain {
          background-size: contain;
        }

        /* per breakpoint */
        @each $breakpoint, $value in $grid-breakpoints {
          @include media-breakpoint-only($breakpoint, $grid-breakpoints) {
            &.cb-img-contain-#{$breakpoint}-only {
              background-size: contain !important;
            }
          }

          @include media-breakpoint-up($breakpoint, $grid-breakpoints) {
            &.cb-img-contain-#{$breakpoint}-up {
              background-size: contain !important;
            }
          }

          @include media-breakpoint-down($breakpoint, $grid-breakpoints) {
            &.cb-img-contain-#{$breakpoint}-down {
              background-size: contain !important;
            }
          }
        }
      }

      &.cb-promo-modal-top {
        flex-direction: column;

        .cb-promo-modal-img {
          position: relative;
          height: 384px;
          min-height: 384px;

          .cb-btn-close {
            position: absolute;
            top: 16px;
            right: 16px;
          }

          @include media-breakpoint-only("md", $grid-breakpoints) {
            height: 362px;
            min-height: 362px;
          }

          @include media-breakpoint-only("sm", $grid-breakpoints) {
            height: 267px;
            min-height: 267px;
          }

          @include media-breakpoint-only("xs", $grid-breakpoints) {
            height: 153px;
            min-height: 153px;
          }
        }
      }

      &.cb-promo-modal-left {
        .cb-promo-modal-container {
          margin-left: 50%;
        }
      }

      &.cb-promo-modal-right {
        .cb-promo-modal-img {
          right: 0;

          .cb-btn-close {
            position: absolute;
            top: 16px;
            right: 16px;
          }
        }
      }

      &.cb-promo-modal-left,
      &.cb-promo-modal-right {
        position: relative;

        .cb-promo-modal-img {
          position: absolute;
          top: 0;
          bottom: 0;

          width: 50%;
          min-width: 50%;
        }

        .cb-promo-modal-container {
          width: 50%;
          min-width: 50%;
        }

        @include media-breakpoint-only("xs", $grid-breakpoints) {
          flex-direction: column;

          .cb-promo-modal-img {
            position: relative;

            width: 100%;
            min-width: 100%;
            height: 153px;
            min-height: 153px;
            margin-right: 0;

            border-top-right-radius: $modal-border-radius;

            .cb-btn-close {
              position: absolute;
              top: 16px;
              right: 16px;
            }
          }

          .cb-promo-modal-container {
            width: 100%;
            min-width: 100%;
            margin-left: 0;
            border-bottom-right-radius: $modal-border-radius;

            .cb-modal-title {
              max-width: 100%;
            }
          }
        }
      }
    }
  }
}

.cb-modal-overlay {
  position: fixed;
  z-index: $z-index-modal-background;
  inset: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: $modal-backdrop-bg;

  &.cb-modal-over-tooltip {
    z-index: $z-index-modal-background-high;
  }
}

.cb-modal-open {
  overflow: hidden;
}

.cb-photo-gallery-modal {
  .cb-modal-overlay {
    background-color: $black1;

    .cb-modal-container {
      width: 100vw;
      max-width: 100vw;
      height: 100vh;
      max-height: 100vh;
      margin: 0;

      border-radius: 0;

      .cb-modal-content {
        margin-bottom: 0;
      }
    }
  }
}

/* ------------------------------------ KEYBOARD FOCUS */
[data-cb-input-m="keyboard"] {
  .cb-modal {
    &.cb-video-modal {
      .cb-modal-container {
        .cb-modal-footer {
          .cb-btn-row {
            position: relative;

            .cb-btn[data-cb-modal-close] {
              position: absolute;
              right: -200px;

              &:focus {
                right: 4px;

                &::after {
                  box-shadow: inset 0 0 0 2px $white;
                }
              }
            }
          }
        }
      }
    }
  }
}

/* Animation Style */
@keyframes fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fade-out {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@keyframes slide-in {
  from {
    transform: translateY(15%);
  }

  to {
    transform: translateY(0);
  }
}

@keyframes slide-out {
  from {
    transform: translateY(0);
  }

  to {
    transform: translateY(-10%);
  }
}

/* For no motion */
@keyframes fade-in-motion {
  from {
    opacity: 1;
  }

  to {
    opacity: 1;
  }
}

@keyframes fade-out-motion {
  from {
    opacity: 0;
  }

  to {
    opacity: 0;
  }
}

@keyframes slide-in-motion {
  from {
    transform: translateY(0);
  }

  to {
    transform: translateY(0);
  }
}

@keyframes slide-out-motion {
  from {
    transform: translateY(-10%);
  }

  to {
    transform: translateY(-10%);
  }
}
