/*
 * CB Buttons
 */

@use "sass:color";

@import "apricot-variable";
@import "../mixins/button";
@import "../mixins/link";
@import "../mixins/breakpoint";

/* ------------------------------------ PULSE */
$left-pos: -9999px;
$x1: -$left-pos - $dot-spacing;
$x2: -$left-pos;
$x3: -$left-pos + $dot-spacing;

.cb-btn {
  user-select: none;

  display: inline-flex;
  align-items: center;
  justify-content: center;

  box-sizing: border-box;
  padding: $btn-padding-v - 1px $btn-padding-h - 1px $btn-padding-v - 1px $btn-padding-h - 1px;

  font-family: $btn-font-family;
  font-size: $btn-font-size;
  font-weight: $btn-font-weight;
  line-height: $btn-line-height;
  color: $btn-font-color;
  text-decoration: none;

  background-color: transparent;
  border-color: $btn-border-color;
  border-style: solid;
  border-width: $btn-border-width;
  border-radius: $btn-border-radius;

  transition: $btn-transition;

  .cb-glyph,
  .cb-icon {
    font-size: 1em !important;
    line-height: 1.7143em !important;
  }

  &:hover,
  &.cb-hover {
    text-decoration: none;
    background-color: $gray5;
    box-shadow: inset 0 0 0 1px $blue5;
  }

  &.cb-hover-shade {
    &:hover,
    &.cb-hover {
      background: rgb(40 54 154 / 16%);
    }
  }

  &:focus,
  &.cb-focus {
    text-decoration: underline;
    background-color: transparent;
    outline: none;
    box-shadow: inset 0 0 0 2px $blue5;
  }

  /* Disabled comes first so active can properly restyle */
  @include button-disabled($gray5, $gray5, $gray3);

  &.cb-hover-shade {
    &:disabled,
    &.cb-disabled {
      color: rgb(0 0 0 / 30%) !important;
      background: rgb(0 0 0 / 16%);
    }
  }

  &:not(.cb-btn-square):not(.cb-btn-circle) {
    .cb-glyph,
    .cb-icon {
      margin-left: 8px;
    }

    &.cb-btn-icon-left {
      .cb-glyph,
      .cb-icon {
        margin-right: 8px;
        margin-left: 0;
      }
    }
  }
}

/* ------------------------------------ SIZE */
.cb-btn-sm:not(.cb-btn-tag),
.cb-btn-small:not(.cb-btn-tag) {
  @include button-size($btn-padding-sm-v, $btn-padding-sm-h);
}

.cb-btn-lg {
  &:not(.cb-btn-card-yellow),
  &:not(.cb-btn-card-primary),
  &:not(.cb-btn-card-secondary),
  &:not(.cb-btn-square),
  &:not(.cb-btn-circle),
  &:not(.cb-btn-floating),
  &:not(.cb-btn-greyscale),
  &:not(.cb-btn-naked) {
    @include button-size($btn-padding-lg-v, $btn-padding-lg-h);

    font-size: $btn-font-size-lg;
    font-weight: 500;
    line-height: $btn-line-height-lg;
  }
}

/* ------------------------------------ LIGHT */
.cb-btn-light {
  @include light();
}

/* ------------------------------------ BLACK */
.cb-btn-black {
  color: $black1;
  border-color: $black1;

  @include black-link();

  &:hover,
  &.cb-hover {
    box-shadow: inset 0 0 0 1px $black1;
  }

  &.cb-hover-shade {
    &:hover,
    &.cb-hover {
      background: rgb(0 0 0 / 16%);
    }
  }

  &.cb-white-bg {
    &:hover,
    &.cb-hover {
      background: $gray5 !important;
    }
  }

  &:focus,
  &.cb-focus {
    box-shadow: inset 0 0 0 2px $black1;
  }

  @include button-disabled($gray5, $gray5, $gray3);

  &.cb-hover-shade {
    &:disabled,
    &.cb-disabled {
      color: rgb(0 0 0 / 30%) !important;
      background: rgb(0 0 0 / 16%);
    }
  }

  &.cb-btn-light {
    @include light();
  }

  /* Palette */
  @each $key, $value in $palette-colors {
    &.cb-palette-#{$key} {
      color: $value;
      border-color: $value;

      &:hover,
      &.cb-hover {
        background-color: color.mix($white, $value, 90%);
        box-shadow: inset 0 0 0 1px $value;
      }

      &:focus,
      &.cb-focus {
        box-shadow: inset 0 0 0 2px $value;
      }
    }
  }
}

/* ------------------------------------ PRIMARY */
.cb-btn-primary {
  color: $white;
  background-color: $blue5;
  border-color: $blue5;

  &:hover,
  &.cb-hover {
    /* $blue5-shade */
    background-color: color.mix($black, $blue5, 50%);
    border-color: color.mix($black, $blue5, 50%);
    box-shadow: none;
  }

  &:focus,
  &.cb-focus {
    background-color: $blue5;
    border-color: $white;
    box-shadow: 0 0 0 2px $blue5;
  }

  &:not(.cb-btn-pulse) {
    &:focus,
    &.cb-focus,
    &:hover,
    &.cb-hover {
      &:not(:disabled) {
        &:not(.cb-disabled) {
          color: $white;
        }
      }
    }
  }

  @include button-disabled($gray5, $gray5, $gray3);

  /* Palette */
  @each $key, $value in $palette-colors {
    &.cb-palette-#{$key} {
      background-color: $value;
      border-color: $value;

      &:hover,
      &.cb-hover {
        /* $blue5-shade */
        background-color: color.mix($black, $value, 50%);
        border-color: color.mix($black, $value, 50%);
      }

      &:focus,
      &.cb-focus {
        background-color: $value;
        border-color: $white;
        box-shadow: 0 0 0 2px $value;
      }
    }
  }

  &.cb-btn-light {
    @include primary-light();
  }
}

/* ------------------------------------ YELLOW */
.cb-btn-yellow {
  color: $black1;
  background-color: $yellow1;
  border-color: $black1;

  &:hover,
  &.cb-hover {
    text-decoration: none;
    background-color: $yellow1;
    box-shadow: inset 0 0 0 1px $black1;
  }

  &:focus,
  &.cb-focus {
    background-color: $yellow1;
    border-color: $white;
    box-shadow: 0 0 0 2px $black1;
  }

  &:not(.cb-btn-pulse) {
    &:focus,
    &.cb-focus,
    &:hover,
    &.cb-hover {
      &:not(:disabled) {
        &:not(.cb-disabled) {
          color: $black1;
        }
      }
    }
  }

  @include button-disabled($gray5, $gray5, $gray3);

  &.cb-btn-light {
    @include yellow-light();
  }
}

/* ------------------------------------ CARD */
.cb-btn-card-primary {
  font-weight: 400;
  color: $black1;
  border-color: $black1;

  @include black-link();

  &.cb-btn-sm,
  &.cb-btn-small {
    padding: 7px 15px;
    font-size: $btn-font-size-sm;
    line-height: $btn-line-height-sm;
  }

  &:hover,
  &.cb-hover {
    box-shadow: inset 0 0 0 1px $black1;
  }

  &:focus,
  &.cb-focus {
    box-shadow: inset 0 0 0 2px $black1;
  }

  @include button-disabled($gray5, $gray5, $gray3);
}

.cb-btn-card-secondary {
  padding: $btn-padding-v - 1px 0;

  font-weight: 500;
  color: $black1;
  text-decoration: underline;

  background-color: transparent;
  border-color: transparent;
  border-radius: 0;

  &:hover,
  &.cb-hover {
    color: $blue5;
    text-decoration: underline;

    background-color: transparent;
    border-color: transparent;
    box-shadow: none;
  }

  @each $key, $value in $palette-colors {
    &.cb-palette-#{$key} {
      &:hover,
      &.cb-hover {
        color: $value;
      }
    }
  }

  &:focus,
  &.cb-focus {
    position: relative;

    background-color: transparent;
    border-color: transparent !important;
    outline: none !important;
    box-shadow: none !important;

    &::after {
      content: "";

      position: absolute;
      top: -1px;
      left: -20px;

      display: block;

      width: calc(100% + 42px);
      height: calc(100% + 2px);

      border-radius: $btn-border-radius;
      box-shadow: inset 0 0 0 2px $black1;
    }
  }

  &.cb-btn-sm,
  &.cb-btn-small {
    padding: 7px 0;
    font-size: $btn-font-size-sm;
    line-height: $btn-line-height-sm;

    &:focus,
    &.cb-focus {
      &::after {
        top: -1px;
        left: -12px;
        width: calc(100% + 24px);
        height: calc(100% + 2px);
      }
    }
  }

  @include button-disabled(transparent, transparent, $gray3);
}

/* ------------------------------------ FILTER */
.cb-btn-filter {
  color: $black1;
  border-color: $gray4;

  @include black-link();

  &:hover,
  &.cb-hover {
    background-color: $gray5;
    box-shadow: inset 0 0 0 1px $black1;
  }

  &:focus,
  &.cb-focus {
    box-shadow: inset 0 0 0 2px $black1;
  }

  &.cb-btn-light {
    background-color: $white;

    &:hover,
    &:focus {
      color: $black1 !important;
      background-color: $gray5;
    }
  }

  @include button-disabled($gray5, $gray5, $gray3);

  &.cb-filter-active,
  &.cb-filter-open {
    color: $blue5;
    background-color: $blue5-tint2;
    border-color: $blue5;

    &:hover,
    &.cb-hover {
      background-color: $blue5-tint1;
      box-shadow: inset 0 0 0 1px $blue5;
    }

    &:focus,
    &.cb-focus {
      background-color: $white;
      box-shadow: inset 0 0 0 2px $blue5;
    }
  }

  /* Palette */
  @each $key, $value in $palette-colors {
    &.cb-palette-#{$key} {
      &.cb-filter-active,
      &.cb-filter-open {
        color: $value !important;
        background-color: color.mix($white, $value, 95%);
        border-color: $value;

        &:hover,
        &.cb-hover {
          background-color: color.mix($white, $value, 90%);
          box-shadow: inset 0 0 0 1px $value;
        }

        &:focus,
        &.cb-focus {
          background-color: $white;
          box-shadow: inset 0 0 0 2px $value;
        }
      }
    }
  }
}

/* ------------------------------------ DATE PICKER */
.cb-btn-date-picker {
  .cb-glyph,
  .cb-icon {
    margin-right: 12px;
    margin-left: 0 !important;
  }

  .cb-date-picker-end {
    position: relative;
    padding-left: 25px;

    &::before {
      content: "";

      position: absolute;
      top: 4px;
      left: 12px;

      display: block;

      width: 1px;
      height: 16px;

      background-color: $black1;
    }
  }

  &.cb-filter-open,
  &.cb-filter-active {
    .cb-date-picker-end {
      &::before {
        background-color: $blue5;
      }
    }
  }

  /* Palette */
  @each $key, $value in $palette-colors {
    &.cb-palette-#{$key} {
      &.cb-filter-active,
      &.cb-filter-open {
        .cb-date-picker-end {
          &::before {
            background-color: $value;
          }
        }
      }
    }
  }
}

/* ------------------------------------ RESPONSIVE LIGHT BUTTONS */
@each $breakpoint, $value in $grid-breakpoints {
  @include media-breakpoint-only($breakpoint, $grid-breakpoints) {
    .cb-btn-light-#{$breakpoint} {
      @include light();

      &.cb-btn-primary {
        @include primary-light();

        @each $key, $value in $palette-colors {
          &.cb-palette-#{$key} {
            color: $value;
          }
        }
      }

      &.cb-btn-black {
        @include light();
      }

      &.cb-btn-yellow {
        @include yellow-light();
      }
    }

    .cb-btn-sm-#{$breakpoint},
    .cb-btn-sm-#{$breakpoint}-only,
    .cb-btn-small-#{$breakpoint} {
      @include button-size($btn-padding-sm-v, $btn-padding-sm-h);
    }

    .cb-btn-lg-#{$breakpoint}-only {
      @include button-size($btn-padding-lg-v, $btn-padding-lg-h);

      font-size: $btn-font-size-lg;
      font-weight: 500;
      line-height: $btn-line-height-lg;
    }
  }

  @include media-breakpoint-up($breakpoint, $grid-breakpoints) {
    .cb-btn-light-#{$breakpoint}-up {
      @include light();

      &.cb-btn-primary {
        @include primary-light();
      }

      &.cb-btn-black {
        @include light();
      }

      &.cb-btn-yellow {
        @include yellow-light();
      }
    }

    .cb-btn-sm-#{$breakpoint}-up,
    .cb-btn-small-#{$breakpoint}-up {
      @include button-size($btn-padding-sm-v, $btn-padding-sm-h);
    }

    .cb-btn-lg-#{$breakpoint}-up {
      @include button-size($btn-padding-lg-v, $btn-padding-lg-h);

      font-size: $btn-font-size-lg;
      font-weight: 500;
      line-height: $btn-line-height-lg;
    }
  }

  @include media-breakpoint-down($breakpoint, $grid-breakpoints) {
    .cb-btn-light-#{$breakpoint}-down {
      @include light();

      &.cb-btn-primary {
        @include primary-light();
      }

      &.cb-btn-black {
        @include light();
      }

      &.cb-btn-yellow {
        @include yellow-light();
      }
    }

    .cb-btn-sm-#{$breakpoint}-down,
    .cb-btn-small-#{$breakpoint}-down {
      @include button-size($btn-padding-sm-v, $btn-padding-sm-h);
    }

    .cb-btn-lg-#{$breakpoint}-down {
      @include button-size($btn-padding-lg-v, $btn-padding-lg-h);

      font-size: $btn-font-size-lg;
      font-weight: 500;
      line-height: $btn-line-height-lg;
    }
  }
}

/* ------------------------------------ TAG */
.cb-btn-tag {
  padding: 7px 11px;

  font-weight: 500;
  color: $gray1;

  background-color: $gray5;
  border-color: $gray4;
  border-radius: 8px;

  .cb-glyph,
  .cb-icon {
    font-size: $font-size-x-small;
    line-height: $line-height-x-small;
  }

  &:hover,
  &.cb-hover {
    text-decoration: none;
    border-color: $gray1;
    box-shadow: inset 0 0 0 1px $gray1;

    span {
      text-decoration: none;
    }
  }

  &:focus,
  &.cb-focus {
    text-decoration: underline;
    background-color: $gray5;
    border-color: $gray1;
    box-shadow: 0 0 1px 2px $gray1;
  }

  &:not(.cb-btn-pulse) {
    &:focus,
    &.cb-focus,
    &:hover,
    &.cb-hover {
      &:not(:disabled) {
        &:not(.cb-disabled) {
          color: $gray1;
        }
      }
    }
  }

  @include button-disabled($gray5, $gray5, $gray3);

  &.cb-btn-sm,
  &.cb-btn-small {
    .cb-glyph,
    .cb-icon {
      font-size: $font-size-x-small !important;
      line-height: 1em !important;
    }

    & {
      padding: 2px 8px;

      font-size: $font-size-x-small;
      font-weight: 400;
      line-height: 1.5em;

      border-radius: 4px;
    }
  }
}

/* ------------------------------------ SQUARE */
.cb-btn-square {
  width: 3em;
  height: 3rem;
  padding: 0;

  font-size: 1rem;
  line-height: 1em;

  border-radius: $btn-border-radius-input;

  &.cb-btn-sm,
  &.cb-btn-small {
    width: 1.5rem;
    height: 1.5rem;
    padding: 0;

    font-size: $font-size-small;
    line-height: 0.875em;
  }

  &:focus,
  &.cb-focus {
    text-decoration: none;
  }
}

/* ------------------------------------ CLOSE */
.cb-btn-square {
  &.cb-btn-close {
    @include close();
  }
}

/* ------------------------------------ CIRCLE */
.cb-btn-circle {
  width: 3em;
  height: 3rem;
  padding: 0;

  font-size: 1rem;
  line-height: 1.5em;

  border-radius: 50%;

  &.cb-btn-sm,
  &.cb-btn-small {
    width: 1.5rem;
    height: 1.5rem;
    padding: 0;

    font-size: $font-size-small;
    line-height: 0.875em;
  }

  &:focus,
  &.cb-focus {
    text-decoration: none;
  }
}

/* ------------------------------------ FLOATING */
.cb-btn-floating {
  color: $black1;
  background-color: $white;
  border-color: transparent;
  box-shadow: 0 0 8px rgb(0 0 0 / 20%);

  &:hover,
  &.cb-hover {
    &:not(:disabled),
    &:not(.cb-disabled) {
      background-color: $gray5;
      box-shadow: 0 0 8px rgb(0 0 0 / 20%);
    }
  }

  &:focus,
  &.cb-focus {
    &:not(:disabled),
    &:not(.cb-disabled) {
      box-shadow: inset 0 0 1px 2px $gray1;
    }
  }
}

/* ------------------------------------ GRAYSCALE */
.cb-btn-greyscale {
  color: $black1;
  background-color: $white;
  border-color: $gray4;

  &:hover,
  &.cb-hover {
    &:not(:disabled) {
      &:not(.cb-disabled) {
        color: $black1;
        background-color: $gray5;
        box-shadow: none;
      }
    }
  }

  &:focus,
  &.cb-focus {
    &:not(:disabled) {
      &:not(.cb-disabled) {
        color: $black1;
        box-shadow: inset 0 0 1px 2px $gray1;
      }
    }
  }

  &.cb-white-color {
    &:focus,
    &.cb-focus {
      &:not(:disabled) {
        &:not(.cb-disabled) {
          box-shadow: inset 0 0 1px 2px $white;
        }
      }
    }
  }
}

/* ------------------------------------ NAKED */
.cb-btn-naked {
  color: $blue5;
  background-color: transparent;
  border-color: transparent;

  &:hover,
  &.cb-hover {
    text-decoration: underline;
    background-color: transparent;
    border-color: transparent;
    box-shadow: none;
  }

  &:focus,
  &.cb-focus {
    background-color: transparent;
    border-color: $blue5;
    box-shadow: inset 0 0 0 3px $blue5;
  }

  @include button-disabled(transparent, transparent, $gray3);

  &.cb-btn-light {
    color: $white;

    &:focus,
    &.cb-focus {
      background-color: transparent;
      border-color: $white;
      box-shadow: inset 0 0 0 3px $white;
    }
  }

  &.cb-btn-black {
    color: $black1;
    background-color: transparent;
    border-color: transparent;

    @include button-disabled(transparent, transparent, $gray3);

    &:hover,
    &.cb-hover {
      text-decoration: underline;
      background-color: transparent;
      border-color: transparent;
      box-shadow: none;
    }

    &:focus,
    &.cb-focus {
      background-color: transparent;
      border-color: $black1;
      box-shadow: inset 0 0 0 3px $black1;
    }
  }

  &.cb-no-padding {
    border-radius: 0;

    &:focus,
    &.cb-focus {
      position: relative;

      background-color: transparent;
      border-color: transparent !important;
      outline: none !important;
      box-shadow: none !important;

      &::after {
        content: "";

        position: absolute;
        top: 0;
        left: -4px;

        display: block;

        width: calc(100% + 8px);
        height: 100%;

        border-radius: 4px;
        box-shadow: inset 0 0 0 2px $blue5;
      }
    }

    &.cb-no-outline {
      &:focus,
      &.cb-focus {
        position: relative;

        background-color: transparent;
        border-color: transparent !important;
        outline: none !important;
        box-shadow: none !important;

        &::after {
          content: "";

          position: absolute;
          top: -6px;
          left: -12px;

          display: block;

          width: calc(100% + 24px);
          height: calc(100% + 12px);

          border-radius: $btn-border-radius;
          box-shadow: inset 0 0 0 3px $blue5;
        }
      }
    }
  }

  &.cb-btn-reverse {
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
}

a {
  &.cb-btn-square,
  &.cb-btn-circle {
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
}

/* ------------------------------------ BLOCK BUTTON */
.cb-btn-block {
  display: block;
  width: 100%;
}

/* Specificity overrides */
input[type="submit"],
input[type="reset"],
input[type="button"] {
  &.cb-btn-block {
    width: 100%;
  }
}

.cb-btn-effect1 {
  background-color: rgb(255 255 255 / 25%);
}

/* ------------------------------------ BUTTON ROW */
.cb-btn-row {
  flex-flow: wrap;
  margin-top: -16px;
  margin-left: -16px;

  /* To prevent horizontal space */
  font-size: 0;

  .cb-btn {
    flex-shrink: 0;
    margin-top: 16px;
    margin-left: 16px;
  }

  .cb-btn + .cb-btn {
    margin-left: 16px;
  }

  &.cb-btn-row-sm {
    margin-top: -8px;
    margin-left: -8px;

    .cb-btn {
      margin-top: 8px;
      margin-left: 8px;
      font-family: $btn-font-family-sm;

      &:not(.cb-btn-tag) {
        @include button-size($btn-padding-sm-v, $btn-padding-sm-h);
      }

      &.cb-btn-square {
        width: 1.5rem;
        height: 1.5rem;
        padding: 0;

        font-size: $font-size-small;
        line-height: 0.875em;

        &.cb-btn-close {
          width: 1.5rem;
          height: 1.5rem;
          padding: 0;

          font-size: $font-size-small;
          line-height: 0.875em;
        }
      }

      &.cb-btn-circle {
        width: 1.5rem;
        height: 1.5rem;
        padding: 0;

        font-size: $font-size-small;
        line-height: 0.875em;
      }
    }

    .cb-btn + .cb-btn {
      margin-left: 8px;
    }
  }
}

/* ------------------------------------ TAG SET */
.cb-btn-row {
  &.cb-tag-set {
    font-size: 1rem;

    > .cb-glyph,
    > .cb-icon {
      flex-shrink: 0;
      margin-top: 16px;
      margin-left: 16px;
    }

    .cb-glyph,
    .cb-icon {
      font-size: 1em;
    }

    &.cb-btn-row-sm {
      > .cb-glyph,
      > .cb-icon {
        margin-top: 8px;
      }
    }
  }
}

/* ------------------------------------ PULSE */
.cb-btn-pulse {
  position: relative;
  color: transparent;

  .cb-glyph,
  .cb-icon {
    display: none;
  }

  .cb-btn-pulse-dots {
    position: absolute;
    top: 20px;
    left: 46%;

    .cb-btn-dots {
      @include dot();

      position: relative;
      left: $left-pos;
      box-shadow: $x1 0 0 0 $dot-color, $x2 0 0 0 $dot-color, $x3 0 0 0 $dot-color;
      animation: dot-pulse 1.5s infinite linear;
    }
  }

  &.cb-btn-sm,
  &.cb-btn-small {
    .cb-btn-pulse-dots {
      top: 16px;
    }
  }

  &.cb-btn-lg {
    .cb-btn-pulse-dots {
      top: 28px;
    }
  }

  &.cb-btn-light {
    .cb-btn-pulse-dots {
      .cb-btn-dots {
        box-shadow: $x1 0 0 0 $white, $x2 0 0 0 $white, $x3 0 0 0 $white;
        animation: dot-pulse-white 1.5s infinite linear;
      }
    }
  }

  &.cb-btn-naked {
    .cb-btn-pulse-dots {
      .cb-btn-dots {
        box-shadow: $x1 0 0 0 $blue5, $x2 0 0 0 $blue5, $x3 0 0 0 $blue5;
        animation: dot-pulse 1.5s infinite linear;
      }
    }

    &.cb-btn-light {
      color: transparent;

      .cb-btn-pulse-dots {
        .cb-btn-dots {
          box-shadow: $x1 0 0 0 $white, $x2 0 0 0 $white, $x3 0 0 0 $white;
          animation: dot-pulse-white 1.5s infinite linear;
        }
      }
    }

    &.cb-btn-black {
      color: transparent;

      .cb-btn-dots {
        box-shadow: $x1 0 0 0 $black1, $x2 0 0 0 $black1, $x3 0 0 0 $black1;
        animation: dot-pulse-black 1.5s infinite linear;
      }
    }
  }

  &.cb-btn-black,
  &.cb-btn-yellow {
    .cb-btn-pulse-dots {
      .cb-btn-dots {
        box-shadow: $x1 0 0 0 $black1, $x2 0 0 0 $black1, $x3 0 0 0 $black1;
        animation: dot-pulse-black 1.5s infinite linear;
      }
    }
  }

  &.cb-btn-black {
    &.cb-btn-light {
      color: transparent;

      .cb-btn-pulse-dots {
        .cb-btn-dots {
          box-shadow: $x1 0 0 0 $white, $x2 0 0 0 $white, $x3 0 0 0 $white;
          animation: dot-pulse-white 1.5s infinite linear;
        }
      }

      &:hover,
      &.cb-hover,
      &:focus,
      &.cb-focus {
        cursor: default;
        color: transparent !important;
        text-decoration: none;
      }
    }

    &.cb-palette-blue5 {
      color: transparent;

      .cb-btn-pulse-dots {
        .cb-btn-dots {
          box-shadow: $x1 0 0 0 $blue5, $x2 0 0 0 $blue5, $x3 0 0 0 $blue5;
          animation: dot-pulse-blue5 1.5s infinite linear;
        }
      }
    }

    &.cb-palette-blue2 {
      color: transparent;

      .cb-btn-pulse-dots {
        .cb-btn-dots {
          box-shadow: $x1 0 0 0 $blue2, $x2 0 0 0 $blue2, $x3 0 0 0 $blue2;
          animation: dot-pulse-blue2 1.5s infinite linear;
        }
      }
    }

    &.cb-palette-purple1 {
      color: transparent;

      .cb-btn-pulse-dots {
        .cb-btn-dots {
          box-shadow: $x1 0 0 0 $purple1, $x2 0 0 0 $purple1, $x3 0 0 0 $purple1;
          animation: dot-pulse-purple1 1.5s infinite linear;
        }
      }
    }
  }

  &:hover,
  &.cb-hover,
  &:focus,
  &.cb-focus {
    cursor: default;
    color: transparent !important;
    text-decoration: none;
  }

  &:hover,
  &.cb-hover {
    cursor: pointer;
  }

  &.cb-btn-primary {
    .cb-btn-pulse-dots {
      .cb-btn-dots {
        box-shadow: $x1 0 0 0 $white, $x2 0 0 0 $white, $x3 0 0 0 $white;
        animation: dot-pulse-white 1.5s infinite linear;
      }
    }

    &.cb-btn-light {
      color: transparent;

      .cb-btn-pulse-dots {
        .cb-btn-dots {
          box-shadow: $x1 0 0 0 $blue5, $x2 0 0 0 $blue5, $x3 0 0 0 $blue5;
          animation: dot-pulse-blue5 1.5s infinite linear;
        }
      }

      &:focus,
      &.cb-focus {
        cursor: default;
        color: transparent !important;
        text-decoration: none;

        .cb-btn-pulse-dots {
          .cb-btn-dots {
            box-shadow: $x1 0 0 0 $blue5, $x2 0 0 0 $blue5, $x3 0 0 0 $blue5;
            animation: dot-pulse-blue5 1.5s infinite linear;
          }
        }
      }

      &:hover,
      &.cb-hover {
        cursor: default;
        color: transparent !important;
        text-decoration: none;

        .cb-btn-pulse-dots {
          .cb-btn-dots {
            box-shadow: $x1 0 0 0 $white, $x2 0 0 0 $white, $x3 0 0 0 $white;
            animation: dot-pulse-white 1.5s infinite linear;
          }
        }
      }
    }
  }

  &.cb-btn-yellow {
    &.cb-btn-light {
      color: transparent;

      .cb-btn-pulse-dots {
        .cb-btn-dots {
          box-shadow: $x1 0 0 0 $black1, $x2 0 0 0 $black1, $x3 0 0 0 $black1;
          animation: dot-pulse-black 1.5s infinite linear;
        }
      }

      &:focus,
      &.cb-focus {
        cursor: default;
        color: transparent !important;
        text-decoration: none;

        .cb-btn-pulse-dots {
          .cb-btn-dots {
            box-shadow: $x1 0 0 0 $black1, $x2 0 0 0 $black1, $x3 0 0 0 $black1;
            animation: dot-pulse-black 1.5s infinite linear;
          }
        }
      }

      &:hover,
      &.cb-hover {
        cursor: default;
        color: transparent !important;
        text-decoration: none;

        .cb-btn-pulse-dots {
          .cb-btn-dots {
            box-shadow: $x1 0 0 0 $white, $x2 0 0 0 $white, $x3 0 0 0 $white;
            animation: dot-pulse-white 1.5s infinite linear;
          }
        }
      }
    }
  }

  &:disabled {
    cursor: not-allowed !important;
  }
}

@keyframes dot-pulse {
  0% {
    box-shadow: $x1 0 0 -5px $dot-color, $x2 0 0 0 $dot-color, $x3 0 0 2px $dot-color;
  }

  25% {
    box-shadow: $x1 0 0 0 $dot-color, $x2 0 0 2px $dot-color, $x3 0 0 0 $dot-color;
  }

  50% {
    box-shadow: $x1 0 0 2px $dot-color, $x2 0 0 0 $dot-color, $x3 0 0 -5px $dot-color;
  }

  75% {
    box-shadow: $x1 0 0 0 $dot-color, $x2 0 0 -5px $dot-color, $x3 0 0 0 $dot-color;
  }

  100% {
    box-shadow: $x1 0 0 -5px $dot-color, $x2 0 0 0 $dot-color, $x3 0 0 2px $dot-color;
  }
}

@keyframes dot-pulse-white {
  0% {
    box-shadow: $x1 0 0 -5px $white, $x2 0 0 0 $white, $x3 0 0 2px $white;
  }

  25% {
    box-shadow: $x1 0 0 0 $white, $x2 0 0 2px $white, $x3 0 0 0 $white;
  }

  50% {
    box-shadow: $x1 0 0 2px $white, $x2 0 0 0 $white, $x3 0 0 -5px $white;
  }

  75% {
    box-shadow: $x1 0 0 0 $white, $x2 0 0 -5px $white, $x3 0 0 0 $white;
  }

  100% {
    box-shadow: $x1 0 0 -5px $white, $x2 0 0 0 $white, $x3 0 0 2px $white;
  }
}

@keyframes dot-pulse-black {
  0% {
    box-shadow: $x1 0 0 -5px $black1, $x2 0 0 0 $black1, $x3 0 0 2px $black1;
  }

  25% {
    box-shadow: $x1 0 0 0 $black1, $x2 0 0 2px $black1, $x3 0 0 0 $black1;
  }

  50% {
    box-shadow: $x1 0 0 2px $black1, $x2 0 0 0 $black1, $x3 0 0 -5px $black1;
  }

  75% {
    box-shadow: $x1 0 0 0 $black1, $x2 0 0 -5px $black1, $x3 0 0 0 $black1;
  }

  100% {
    box-shadow: $x1 0 0 -5px $black1, $x2 0 0 0 $black1, $x3 0 0 2px $black1;
  }
}

@keyframes dot-pulse-blue5 {
  0% {
    box-shadow: $x1 0 0 -5px $blue5, $x2 0 0 0 $blue5, $x3 0 0 2px $blue5;
  }

  25% {
    box-shadow: $x1 0 0 0 $blue5, $x2 0 0 2px $blue5, $x3 0 0 0 $blue5;
  }

  50% {
    box-shadow: $x1 0 0 2px $blue5, $x2 0 0 0 $blue5, $x3 0 0 -5px $blue5;
  }

  75% {
    box-shadow: $x1 0 0 0 $blue5, $x2 0 0 -5px $blue5, $x3 0 0 0 $blue5;
  }

  100% {
    box-shadow: $x1 0 0 -5px $blue5, $x2 0 0 0 $blue5, $x3 0 0 2px $blue5;
  }
}

@keyframes dot-pulse-blue2 {
  0% {
    box-shadow: $x1 0 0 -5px $blue2, $x2 0 0 0 $blue2, $x3 0 0 2px $blue2;
  }

  25% {
    box-shadow: $x1 0 0 0 $blue2, $x2 0 0 2px $blue2, $x3 0 0 0 $blue2;
  }

  50% {
    box-shadow: $x1 0 0 2px $blue2, $x2 0 0 0 $blue2, $x3 0 0 -5px $blue2;
  }

  75% {
    box-shadow: $x1 0 0 0 $blue2, $x2 0 0 -5px $blue2, $x3 0 0 0 $blue2;
  }

  100% {
    box-shadow: $x1 0 0 -5px $blue2, $x2 0 0 0 $blue2, $x3 0 0 2px $blue2;
  }
}

@keyframes dot-pulse-purple1 {
  0% {
    box-shadow: $x1 0 0 -5px $purple1, $x2 0 0 0 $purple1, $x3 0 0 2px $purple1;
  }

  25% {
    box-shadow: $x1 0 0 0 $purple1, $x2 0 0 2px $purple1, $x3 0 0 0 $purple1;
  }

  50% {
    box-shadow: $x1 0 0 2px $purple1, $x2 0 0 0 $purple1, $x3 0 0 -5px $purple1;
  }

  75% {
    box-shadow: $x1 0 0 0 $purple1, $x2 0 0 -5px $purple1, $x3 0 0 0 $purple1;
  }

  100% {
    box-shadow: $x1 0 0 -5px $purple1, $x2 0 0 0 $purple1, $x3 0 0 2px $blue2;
  }
}
