/*
 * CB Forms Mixins
 */

@mixin form-control-validation($glyph-color: $font-color-base, $border-color: $input-border, $bg: $input-bk) {
  &.cb-input {
    input,
    textarea {
      border-color: $border-color;

      &:focus {
        border-color: $border-color;
        box-shadow: inset 0 0 1px .5px $border-color;
      }
    }

    .cb-validation-icon {
      &.cb-glyph,
      &.cb-icon {
        color: $glyph-color;
      }
    }
  }

  &.cb-radio,
  .cb-radio {
    input[type="radio"] {
      &::before {
        border-color: $glyph-color;
      }
    }
  }

  &.cb-checkbox,
  .cb-checkbox {
    input[type="checkbox"] {
      &::before {
        border-color: $glyph-color;
      }
    } 
  }

  &.cb-select,
  .cb-select {
    .cb-select-container {
      span {
        &.cb-select {
          >span {
            border-color: $glyph-color;
          }

          &.focus {
            >span {
              border-color: $glyph-color;
              box-shadow: inset 0 0 1px 0.5px $glyph-color;
            }
          }
        }
      }
    }
  }
}