/*
 * CB Images
 */

/* Responsive images (ensure images don't scale beyond their parents) */
.cb-img-fluid {
  @include img-fluid();
}

.cb-img-stretch {
  width: 100%;
  min-width: 100%;
}


/* ------------------------------------ IMAGE THUMBNAILS */
.cb-img-thumbnail {
  background-color: $gray3;
  border: 1px solid $border-color;

  /* Keep them at most 100% wide */
  @include img-fluid();
}

.cb-img-circular {
  border-radius: 50%;
}

.cb-img-grayscale {
  filter: grayscale(100%);
}

.cb-fade-in {
  &.cb-active-effect {
    animation-name: fade-in;
    animation-duration: 3s;
  }
}

.cb-fade-out {
  &.cb-active-effect {
    animation-name: fade-out;
    animation-duration: 3s;
  }
}

.cb-static-img {
  position: relative;
  overflow: hidden;

  .cb-static-img-btn {
    position: absolute;
    right: 1.5rem;
    bottom: 1.5rem;

    padding: 0;

    font-size: 0;

    /* in case it's a button */
    line-height: 0;
    color: $white !important;
    text-decoration: none !important;

    background-color: transparent;
    border: 0;

    .cb-icon {
      position: relative;
      width: calc(2rem + 4px);
      height: calc(2rem + 4px);
      font-size: 2rem;

      &::after {
        content: "";

        position: absolute;
        z-index: 1;
        right: 0;
        bottom: 0;

        width: calc(2rem + 4px);
        height: calc(2rem + 4px);

        opacity: 0.6;
        background-color: $black1;
        border-radius: 50%;

        transition: all 0.3s;
      }

      &::before {
        position: absolute;
        z-index: 2 !important;
        right: 2px;
        bottom: 2px;

        width: 2rem;
        height: 2rem;
      }
    }

    &:hover {
      .cb-icon {
        &::after {
          opacity: 0.9;
        }
      }
    }
  }
}

@media (forced-colors: active) {
  .cb-static-img-btn {
    padding: 4px!important;
    forced-color-adjust: none !important;
    background-color: $white!important;

    .cb-icon {
      width: unset!important;
      height: unset!important;

      font-size: 2rem;   

      background-color: $black1;
      border: 2px solid $black1;
      border-radius: 50%;

      &::after {
        content: none!important;
      }

      &::before {
        position: unset!important;
        z-index: unset!important;
        right: unset!important;
        bottom: unset!important;

        width: unset!important;
        height: unset!important;
      }
    }
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fade-out {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}
