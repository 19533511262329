/*
 * CB Opacity & Tint/Shades
 */

@use "sass:color";

@import "apricot-variable";

/* ------------------------------------ OPACITY */
@for $i from 0 through 9 {
  .cb-opacity-#{$i} {
    opacity: 0.1 * $i;
  }
}

.cb-no-opacity {
  opacity: 1;
}

/* ------------------------------------ TINT */
.cb-blue5-tint-1 {
  background-color: $blue5-tint1!important;
}

.cb-blue5-tint-2 {
  background-color: $blue5-tint2!important;
}

@each $key, $value in $tint-colors {
  .cb-#{$key}-tint-1 {
    background-color: color.mix($white, $value, 90%)!important;
  }
}

@each $key, $value in $tint-colors {
  .cb-#{$key}-tint-2 {
    background-color: color.mix($white, $value, 95%)!important;
  }
}

/* ------------------------------------ SHADE */
.cb-blue5-shade-1 {
  background-color:$blue5-shade1!important;
}

.cb-blue5-shade-2 {
  background-color: $blue5-shade2!important;
}

@each $key, $value in $tint-colors {
  .cb-#{$key}-shade-1 {
    background-color: color.mix($black, $value, 20%)!important;
  }
}

@each $key, $value in $tint-colors {
  .cb-#{$key}-shade-2 {
    background-color: color.mix($black, $value, 50%)!important;
  }
}
