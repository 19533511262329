/*
 * CB Fonts
 */

body {
  @include font-smoothing();
}

@include font-face("CB Glyphs Logo", cb-icons-logo, null, normal, woff ttf);

/* Roboto Fonts */
@include font-face-light(Roboto, Roboto-Thin, 100, normal);
@include font-face-light(Roboto, Roboto-ThinItalic, 100, italic);

@include font-face-light(Roboto, Roboto-Light, 300, normal);
@include font-face-light(Roboto, Roboto-LightItalic, 300, italic);

@include font-face-light(Roboto, Roboto-Regular, 400, normal);
@include font-face-light(Roboto, Roboto-Italic, 400, italic);

@include font-face-light(Roboto, Roboto-Medium, 500, normal);
@include font-face-light(Roboto, Roboto-MediumItalic, 500, italic);

@include font-face-light(Roboto, Roboto-Bold, 700, normal);
@include font-face-light(Roboto, Roboto-BoldItalic, 700, italic);

@include font-face-light(Roboto, Roboto-Black, 900, normal);
@include font-face-light(Roboto, Roboto-BlackItalic, 900, italic);

/* ------------------------------------ FONT: COLOR, SIZE, FAMILY */
.cb-font-size {
  font-size: 1rem;
  line-height: 1.5em;
}

.cb-font-family {
  @include roboto();
}

.cb-font-color {
  color: $black1;
}

/* ------------------------------------ FONT FAMILY */

/* Sans Serif */
.cb-sans-serif {
  font-family: sans-serif !important;
}

/*  Roboto */
.cb-roboto {
  @include roboto();
}

.cb-roboto-thin {
  @include roboto-thin();
}

.cb-roboto-light {
  @include roboto-light();
}

.cb-roboto-medium {
  @include roboto-medium();
}

.cb-roboto-bold {
  @include roboto-bold();
}

.cb-roboto-black {
  @include roboto-black();
}

/* Roboto Italic */
.cb-roboto-italic {
  @include roboto-italic();
}

.cb-roboto-italic-thin {
  @include roboto-italic-thin();
}

.cb-roboto-italic-light {
  @include roboto-italic-light();
}

.cb-roboto-italic-medium {
  @include roboto-italic-medium();
}

.cb-roboto-italic-bold {
  @include roboto-italic-bold();
}

.cb-roboto-italic-black {
  @include roboto-italic-black();
}

/* ------------------------------------ SIZE */

.cb-font-size-xlarge {
  font-size: $font-size-x-large !important;
  line-height: $line-height-x-large !important;
}

.cb-font-size-xlarge-res {
  @include media-breakpoint-up("xs", $grid-breakpoints) {
    font-size: 3.75em !important;
    line-height: 1em !important;
  }

  @include media-breakpoint-up("md", $grid-breakpoints) {
    font-size: 4.5em !important;
    line-height: 1em !important;
  }

  @include media-breakpoint-up("xl", $grid-breakpoints) {
    font-size: 5.25em !important;
    line-height: 1em !important;
  }

  @media (min-width: 1728px) {
    font-size: 6em !important;
    line-height: 1em !important;
  }
}

.cb-font-size-regular {
  font-size: $font-size-base !important;
  line-height: $line-height-base !important;
}

.cb-font-size-small {
  font-size: $font-size-small !important;
  line-height: $line-height-small !important;
}

.cb-font-small {
  font-family: $font-family-base !important;
  font-size: $font-size-small !important;
  font-weight: 400 !important;
  line-height: $line-height-small !important;
}

.cb-font-size-xsmall {
  font-size: $font-size-x-small !important;
  line-height: $line-height-x-small !important;
}

.cb-font-xsmall {
  font-family: $font-family-base !important;
  font-size: $font-size-x-small !important;
  font-weight: 300 !important;
  line-height: $line-height-x-small !important;
}

/* ------------------------------------ NO */

.cb-font-regular,
.cb-no-font {
  font-family: $font-family-base !important;
  font-size: $font-size-base !important;
  font-weight: 400 !important;
  line-height: 1.5 !important;
  color: $font-color-base !important;
}

.cb-no-line-height {
  line-height: 0;
}
