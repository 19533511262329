/*
 * CB Glyph Logos
 */

@import "apricot-variable";
@import "../mixins/font";
@import "../mixins/glyph";

.cb-glyph-logo {
  position: relative;
	display: inline-block;
  background-image: none!important;

  @include glyph-font(CB Glyphs Logo);

	&::before {
	  position: absolute;
	  top: 0;
	  left: 0;
	}

  @each $key, $value in $logo-glyphs {
    &.cb-#{$key} {
      @include glyph-content($value);
    }
  }
}

