/*
 * CB Utils
 */

 
@use "sass:string";

.cb-no-outline {
  outline: 0;
}

.cb-tmp-element {
  position: absolute;
  left: -9999px;
}

.sr-only,
.cb-sr-only {
  position: absolute;

  overflow: hidden;

  width: 1px;
  height: 1px;
  padding: 0;

  clip: rect(0, 0, 0, 0);
  border: 0;
}

.ms-high-contrast {
  display: none;
}

.cb-no-text-select {
  user-select: none;
}

/* DEVELOPMENT */ 

/* Add to body tag */
body {
  &.cb-dev-env {
    &.cb-show-breakpoints {
      &::before {
        position: fixed;
        z-index: 99999;
        left: 50%;
        transform: translateX(-50%);

        display: inline-block;

        padding: 1em;

        color: $white;

        opacity: 0.6;
        background: $gray1;
      }

      @each $breakpoint, $value in $grid-breakpoints {
        @include media-breakpoint-up($breakpoint, $grid-breakpoints) {
          &::before {
            content: string.quote(#{$breakpoint}-#{$value});
          }
        }
      }
    }
  }
}