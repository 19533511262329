/*
 * CB Illustrations Higher Education
 */

@import "apricot-variable";

.cb-ilus-higher-ed {
  @each $key, $value in $illustrations {
    &.cb-ilus-#{$key} {
      &::after {
        background-image: url("#{$illustrations-path}higher-ed/#{$key}.svg");
      }
    }
  }

  &.cb-ilus-shadow {
    &::before {
      background-image: url("#{$illustrations-path}higher-ed/shadow.svg");
    }
  }

  &.cb-ilus-circular {
      background-image: url("#{$illustrations-path}higher-ed/background.svg");
  }
}
