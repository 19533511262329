/*
 * CB Masonry
 */

@import "apricot-variable";
@import "../mixins/breakpoint";
@import "../mixins/masonry";


/* ------------------------------------ PURE CSS */
.cb-masonry-column {
  column-gap: 3rem;
  transition: all $transition-time ease-in-out;

  .cb-masonry-item {
    width: 100%;
    margin-bottom: 3rem;
    transition: all $transition-time ease-in-out;

    -webkit-column-break-inside: avoid;
  }

  &:not(.chrome) {
    .cb-masonry-item {
      display: inline-block;
    }
  }

  &.cb-masonry-4 {
    @include columns(4);

    @include media-breakpoint-only('md', $grid-breakpoints) {
      @include columns(3);
    }

    @include media-breakpoint-only('sm', $grid-breakpoints) {
      @include columns(2);
    }

    @include media-breakpoint-only('xs', $grid-breakpoints) {
      @include columns(1);
    }
  }

  &.cb-masonry-3 {
    @include columns(3);

    @include media-breakpoint-only('sm', $grid-breakpoints) {
      @include columns(2);
    }

    @include media-breakpoint-only('xs', $grid-breakpoints) {
      @include columns(1);
    }
  }

  &.cb-masonry-2 {
    @include columns(2);

    @include media-breakpoint-down('sm', $grid-breakpoints) {
      @include columns(1);
    }
  }
}


/* ------------------------------------ CSS + JS */
.cb-masonry-row {
  overflow-x: hidden;
  display: flex;
  flex-flow: column wrap;

  .cb-masonry-item {
    margin: 0 3rem 3rem 0;

    @include media-breakpoint-only('xs', $grid-breakpoints) {
      margin: 0 0 3rem;
    }
  }

  &.cb-masonry-4 {
    .cb-masonry-item {
      /* 4
       * 48x3
       */
      width: calc(calc(100% - 144px) / 4);

      &[data-cb-oder="4"] {
        margin-right: 0
      }

      /* 3 */
      @include media-breakpoint-only('md', $grid-breakpoints) {
        /* 48x2 */
        width: calc(calc(100% - 96px) / 3);

        &[data-cb-oder="3"] {
          margin-right: 0
        }
      }

      /* 2 */
      @include media-breakpoint-only('sm', $grid-breakpoints) {
        width: calc(calc(100% - 48px) / 2);

        &[data-cb-oder="2"] {
          margin-right: 0
        }
      }

      @include media-breakpoint-only('xs', $grid-breakpoints) {
        width: 100%;
      }
    }
  }

  &.cb-masonry-3 {
    .cb-masonry-item {
      /* 3 */
      width: calc(calc(100% - 48px) / 3);

      &[data-cb-oder="3"] {
        margin-right: 0
      }

      @include media-breakpoint-only('sm', $grid-breakpoints) {
        width: calc(calc(100% - 24px) / 2);

        &[data-cb-oder="2"] {
          margin-right: 0
        }
      }

      @include media-breakpoint-only('xs', $grid-breakpoints) {
        width: 100%;
      }
    }
  }

  &.cb-masonry-2 {
    .cb-masonry-item {
      width: calc(calc(100% - 24px) / 2);

      &[data-cb-oder="2"] {
        margin-right: 0
      }

      @include media-breakpoint-only('xs', $grid-breakpoints) {
        width: 100%;
      }
    }
  }
}